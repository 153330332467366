.sentenceBox {
  margin: 10px;
  padding: 10px;
  border-radius: 7px !important;
  background-color: white;
}

.hostColor {
  color: #1971c2 !important;
  background-color: white !important;
}

.endUserColor {
  color: #087f5b !important;
  background-color: white !important;
}

.notHostIndex {
  border: 3px solid rgba(8, 127, 91, 0.5) !important;
  transition: border 0.5s ease-in !important;
  width: 75%;
  float: left;
}

.hostIndex {
  border: 3px solid rgba(25, 113, 194, 0.5) !important;
  transition: border 0.5s ease-in !important;
  width: 75%;
  float: right;
}

.notGryphonCustomIndex {
  border: 1px solid rgba(73, 80, 87, 0.5) !important;
  transition: border 0.5s ease-in !important;
  color: #495057 !important;
  width: 75%;
  float: right;
}

.hostAlign {
  float: right;
}

.customerAlign {
  float: left;
}

.gryphonCustomColor {
  border: 1px solid rgba(73, 80, 87, 0.5) !important;
  color: rgba(73, 80, 87, 0.5) !important;
  background-color: white !important;
}

.endUser {
  border: 1px solid rgba(8, 127, 91, 0.5) !important;
  transition: border 0.5s ease-in !important;
  width: 75%;
  float: left;
}

.annotationSpeedDialAction {
  box-shadow: none !important;
  border: 1px solid #1971c2 !important;
  border-radius: 10px !important;
  color: #1971c2 !important;
}

.host {
  border: 1px solid rgba(25, 113, 194, 0.5) !important;
  transition: border 0.5s ease-in !important;
  width: 75%;
  float: right;
}

.optionUL {
  font-weight: 300;
  font-size: 16px;
  padding-left: 30px;
}

.transcriptHighlight {
  color: #0070d2;
  background-color: #dbecf8;
  padding: 2px 4px;
  border-radius: 10px;
}

.searchTranscriptHighlight {
  color: #0070d2;
  background-color: #dbecf8;
  border-radius: 10px;
  transition: border 0.5s ease-in !important;
  padding: 2px 4px;
}

.activeClassName {
  background: #0d8244;
  border-radius: 10px;
  color: white;
}

.buttonDisabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.annotationDisabled {
  pointer-events: none !important;
  color: grey !important;
}

.annotationSpeedDial {
  background-color: white;
  min-height: 24px !important;
  margin-right: 24px !important;
  width: 0px;
}

.annotationSpeedDialAction {
  box-shadow: none !important;
  border: 1px solid #1976d2 !important;
  border-radius: 10px !important;
  color: #1976d2 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  height: 32px !important;
}

.annotationSpeedDialText {
  text-transform: capitalize !important;
  line-height: normal !important;
  white-space: nowrap;
}

.annotationSpeedDialAction:hover {
  background-color: #1976d2 !important;
  color: white !important;
}

.annotationSpeedDialText:hover {
  color: white;
}

@media screen and (max-width: 1200px) {
  .annotationSpeedDial {
    margin-right: 0.5em !important;
    margin-left: 0.5em !important;
    width: 0.7em !important;
  }

  .annotationSpeedDialAction:hover {
    border: 1px solid #1864ab !important;
    background-color: #1864ab !important;
    color: white !important;
  }

  .MuiButtonBase-root {
    width: 0.7em !important;
  }

  .MuiChip-label {
    padding-left: 0.5em !important;
    padding-right: 0.15em !important;
  }
}

.speedDialActionButtonBox {
  vertical-align: middle;
  display: flex;
}

.annotationSpeedDialActionContainer {
  display: block;
}

.searchButton {
  display: inline;
  padding: 6px;
  font-size: 12px;
  display: inline;
  margin: auto;
  margin-right: 8px;
}

.searchIconDisable:disabled {
  color: white !important;
}

.gryphCardLikeDislikeDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: auto;
}

.gryphLikeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px !important;
  min-width: 0px !important;

  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: #adb5bd !important;
  margin-right: 5px !important;
}

.gryphDislikeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px !important;
  min-width: 0px !important;

  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: #adb5bd !important;
}

.sc_ah_tooltip_title {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  font-style: "normal";
  color: "#FFFFFF";
  line-height: "12px";
  letter-spacing: "0.1px";
  cursor: pointer;
}

.sc_ah_box_details {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: context-menu;
}
