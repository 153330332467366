.audioDiv {
  position: absolute;
  height: 90px;
  margin-bottom: 20px;
  background: #ffffff;
}

.audioTitle {
  position: absolute;
  width: 126px;
  height: 22px;
  left: 175px;
  top: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #495057;
}

.audioTime {
  position: absolute;
  width: 250px;
  height: 22px;
  left: 172px;
  top: 68px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
}

.audioDuration {
  position: absolute;
  width: 150px;
  height: 17px;
  left: 130px;
  top: 89px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
}

.audioAnalysisPlaylist {
  display: grid;
  justify-items: start;
}

.alertBox {
  margin-left: 16px;
  width: 93%;
}
