.ant-picker-large {
  padding: 0px !important;
}

.ant-picker,
.ant-picker-range,
.ant-picker-large,
.ant-picker-borderless,
.ant-picker-focused,
.ant-picker-input {
  border-radius: 10px;
  border: 1px solid #c4c4c4 !important;
  padding: 8px;
  width: 35%;
}

.ant-picker,
.ant-picker-range,
.ant-picker-large,
.ant-picker-borderless,
.ant-picker-focused,
.ant-picker-input-active:focus-within {
  border: 2px solid #1976d2 !important;
}

.ant-picker-active-bar {
  background: none !important;
}

.ant-picker-suffix {
  display: none;
}

.ant-picker-clear {
  display: none;
}

.ant-picker-panel-container {
  border-radius: 10px;
}

.gn_rangepicker {
  left: 20%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 4px 0 0 4px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 4px 4px 0;
}
