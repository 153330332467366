.annotationModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  padding: 18px;
  border: 2px solid #1976d2;
}

.saveCardDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.actionButton {
  box-shadow: none !important;
  width: 100px;
  border-radius: 10px !important;
  border: none !important;
  color: #1971c2 !important;
  text-transform: capitalize !important;
}

.actionButton:disabled {
  box-shadow: none !important;
  border-radius: 10px !important;
  color: lightgrey !important;
}

.actionButton:hover {
  background-color: #e7f5ff !important;
}

.actionButtonSave {
  border: 1px solid #1971c2 !important;
}

.actionButtonSave:disabled {
  border: 1px solid lightgrey !important;
}

.inputLabel {
  background: white !important;
  font-family: "Inter", sans-serif !important;
  padding-right: 5px !important;
}
