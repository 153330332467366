.more_opt_btn {
  display: none;
}

.mitem_home {
  display: none !important;
}

.mitem_gryph {
  display: none !important;
}

.mitem_search {
  display: none !important;
}

.mitem_insights {
  display: none !important;
}

@media (max-width: 900px) and (min-width: 800px) {
  .more_opt_btn {
    display: block;
  }

  .tbtn_insights {
    display: none !important;
  }

  .mitem_insights {
    display: block !important;
  }
}

@media (max-width: 800px) and (min-width: 700px) {
  .more_opt_btn {
    display: block;
  }

  .tbtn_search {
    display: none !important;
  }

  .mitem_search {
    display: block !important;
  }

  .tbtn_insights {
    display: none !important;
  }

  .mitem_insights {
    display: block !important;
  }
}

@media (max-width: 700px) and (min-width: 600px) {
  .more_opt_btn {
    display: block;
  }

  .tbtn_gryph {
    display: none !important;
  }

  .mitem_gryph {
    display: block !important;
  }

  .tbtn_search {
    display: none !important;
  }

  .mitem_search {
    display: block !important;
  }

  .tbtn_insights {
    display: none !important;
  }

  .mitem_insights {
    display: block !important;
  }
}

@media (max-width: 600px) {
  .more_opt_btn {
    display: block;
  }

  .tbtn_home {
    display: none !important;
  }

  .mitem_home {
    display: block !important;
  }

  .tbtn_gryph {
    display: none !important;
  }

  .mitem_gryph {
    display: block !important;
  }

  .tbtn_search {
    display: none !important;
  }

  .mitem_search {
    display: block !important;
  }

  .tbtn_insights {
    display: none !important;
  }

  .mitem_insights {
    display: block !important;
  }
}