.initialContainers {
  display: inline-flex;
  width: -webkit-fill-available;
  width: -moz-available;
  overflow-x: hidden;
}
.flagsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px;
  gap: 5px;
  min-width: 33%;
  max-width: 33%;
  font-size: small;
  border: 1px solid rgb(0 0 0 / 12%);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  max-height: 160px;
  min-height: 160px;
  overflow: auto;
  padding: 5px;
}
.sectionHeading {
  min-width: 33%;
  font-size: smaller;
  margin-top: 15px;
}
.flagBodyRed {
  border: 1px solid #ff000070;
  border-radius: 10px;
  padding: 5px;
  color: red;
  height: 32px !important;
}
.flagBodyGreen {
  border: 1px solid #00800073;
  border-radius: 10px;
  padding: 5px;
  color: green;
  height: 32px !important;
}
.PromotionContainer {
  margin: 5px;
  min-width: 33%;
  max-width: 33%;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: small;
  border: 1px solid rgb(0 0 0 / 12%);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  max-height: 160px;
  min-height: 160px;
  overflow: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.UserAddedKPIsContainer {
  margin: 5px;
  min-width: 32%;
  max-width: 32%;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: small;
  border: 1px solid rgb(0 0 0 / 12%);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  max-height: 160px;
  min-height: 160px;
  overflow: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hoverButton {
  position: inherit;
  margin-left: 3px;
  border-radius: 20px;
  border: none;
}
.promotedFlagDiv {
  margin: 5px;
}

.UFRecordsContainer {
  margin-top: 10px;
  border: 1px solid rgb(0 0 0 / 12%);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.12);
}

.switch {
  font-size: smaller;
  margin: 10px;
}

.reviewButton {
  border: 1px solid #30303236 !important;
  height: 25px;
}

.tableHeader {
  display: inline-flex;
  align-items: baseline;
}
.dateHeader {
  display: flex;
  justify-content: flex-end;
}

.retrainButton {
  border: 1px solid #16a4e6 !important;
  background-color: #f9f9f9 !important;
  color: #16a4e6 !important;
}

.UAKwithCountContainer {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-size: small;
  margin-top: 20px;
}

.UAKwithCountRed {
  border: 1px solid #ff000070;
  border-radius: 10px;
  padding: 5px;
  color: red;
}

.UAKwithCountGreen {
  border: 1px solid #00800073;
  border-radius: 10px;
  padding: 5px;
  color: green;
}

.UAKForNone {
  border: 1px solid #3d3c3c;
  border-radius: 10px;
  padding: 5px;
  color: black;
}

.routingButtons {
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2 !important;
}
.initialButtons {
  display: flex;
}

.gap {
  height: 15px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 38%;
}

.selectedRow {
  background-color: #cccccc73;
}
.selectedRow:hover {
  background-color: #cccccc73;
}
.mainContainer {
  border: 1px solid #00000026;
  border-radius: 5px;
  margin-top: 12vh;
  display: inline-flex;
  padding: 30px;
  box-shadow: 1px 1px 20px 5px #06730038;
}

.redirectButtons {
  width: 180px;
  height: 8vh;
  margin-right: 12px !important;
  margin-top: 40px !important;
}

.errorMsg {
  color: red;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noJobsExists {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
}

.noReviewedFlags {
  display: flex;
  align-items: center;
}
