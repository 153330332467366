.errorContainer {
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* center a div insie another div*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
}

.errorIcon {
  color: #1971c2;
  width: 50% !important;
  height: 50% !important;
}

.errorMessage {
  color: #495057;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: none;
}

.errorStack {
  width: 100%;
}
