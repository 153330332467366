.new-root {
  display: flex;
  height: 100%;
  background-color: #e5e5e5;
}

.new-content {
  flex-grow: 1;
  height: 100%;
  padding: 16px;
  padding-left: 8px;
  background-color: #e5e5e5;
}

.new-custom-table .ReactVirtualized__Table__headerRow {
  background: #f9f9f9;
}

.ReactVirtualized__Table__headerColumn {
  padding-left: 0px;
  padding-right: 0px;
}

.new-cell-style {
  color: "#656565";
  background-color: #fff;
}

.new-flexWrap {
  display: flex;
  flex-wrap: wrap;
  width: 250px;
}

.new-audioP:hover {
  width: "85%";
  background: #6f6f6f;
  color: red;
}

.new-paper {
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
}

.new-lefter {
  text-align: left;
}

.new-diva {
  text-align: left;
  height: fit-content;
}

.new-speakerType {
  text-align: left;
  font-size: 14px;
}

body {
  overflow: hidden;
}

.new-graph {
  width: 90%;
  height: 240px;
  align-items: center;
}

.new-headRow {
  display: flex;
  justify-content: space-between;
  height: 4.5%;
  margin: 00.5%;
}

.new-loaderParent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.new-scorerParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.new-loader {
  padding-top: 10px;
  padding-right: 20px;
}

.new-processingText {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffee00;
  border-radius: 5px;
  border: 1px solid black;
}

.new-scorer {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  border: 1px solid black;
}

.new-audioRow {
  display: flex;
  justify-content: center;
  height: min-content;
}

.new-audioPlayer {
  height: 30px;
  width: 95%;
}

.new-toggleGroup {
  background-color: #efefef;
  color: "#212529";
  width: 10%;
  border: "solid 2px #eee";
  margin-left: "10px";
  border-radius: "30px";
  font-weight: "400";
  height: 30px;
}

.new-spacer {
  height: 5px;
}

.new-sentenceTableWithSaveButton {
  padding-right: 1%;
  height: 100%;
  margin: 0.5%;
}

.new-saveButton {
  background-color: "#72e967";
  border: none;
}

.new-midRowWithButton {
  display: flex;
  justify-content: space-between;
}

.new-modalButton {
  display: flex;
  justify-content: flex-end;
}

.new-ampopup {
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

.new-ampopup-curtain {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2001;
  background: #fff;
  opacity: 0.5;
}

.new-ampopup-title {
  font-weight: bold;
  font-size: 120%;
  border-radius: 10px;
}

.new-ampopup-content {
  background: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  position: absolute;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  z-index: 2002;
  border-color: #d7d7d7;
  border-style: solid;
  border-radius: 15px;
}

.ampopup-content {
  inset: 2% !important;
}

.new-ampopup-close {
  display: block;
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  background-color: rgb(100, 100, 100);
  background: rgba(100, 100, 100, 0.1)
    url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyBoZWlnaHQ9IjUxMiIgdmVyc2lvbj0iMSIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQ0NS4yIDEwOS4ybC00Mi40LTQyLjRMMjU2IDIxMy42IDEwOS4yIDY2LjhsLTQyLjQgNDIuNEwyMTMuNiAyNTYgNjYuOCA0MDIuOGw0Mi40IDQyLjRMMjU2IDI5OC40bDE0Ni44IDE0Ni44IDQyLjQtNDIuNEwyOTguNCAyNTYiLz48L3N2Zz4=)
    no-repeat center;
  background-size: 80%;
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.chartRowOuter {
  display: block;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  margin: 0.5%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1 1px;
  font-family: Inter, sans-serif;
  overflow-y: auto;
}
.new-chartRow {
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100% !important;
  padding: 8px !important;
}

.new-speakerMetrics {
  width: auto;
  max-width: min-content;
  height: 100%;
  align-items: center;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  padding-top: 3%;
}

.new-kpidiv {
  width: 100%;
  height: auto;
  margin: 0.5%;
  margin-bottom: 8px;
}

.new-analysisdiv {
  width: 97%;
  height: 100%;
}

.salesdiv {
  width: 91%;
  height: 100%;
  margin-top: 8px;
}

.salesStack {
  height: 20%;
  padding-top: 14px;
}

.chartdiv {
  margin-right: 1%;
  z-index: 1;
  padding-top: 1%;
  font-family: "Inter", sans-serif !important;
  overflow: "auto";
  position: "relative";
  left: "2%";
}

.new-chartStack {
  width: 100%;
  height: 80%;
  display: flex;
  margin: 1%;
}

.new-kpiSelectorsForGraph {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  margin-right: 2%;
  flex-wrap: wrap;
}

.new-legendKPIBox {
  width: 97%;
  height: 21%;
  margin-bottom: 1%;
}

.rhap_container {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
}

.rhap_progress-indicator {
  width: 12px;
  height: 12px;
  top: -4px;
}

.new-snipContainer {
  margin-left: 10%;
}

.rhap_additional-controls {
  margin-left: 3.5%;
}

.rhap_volume-controls {
  margin-right: 4.3%;
}

.new-parentOfAll {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  background-color: #e5e5e5;
}

.new-videoRow {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: auto;
}

.new-leftPart {
  height: 100%;
  margin-right: 8px;
  flex-grow: 1;
}

.new-rightPart {
  background-color: #ffffff;
  height: 100%;
}

.new-rightPartOuter {
  width: 50%;
  min-width: 500px;
  max-width: 600px;
  height: 100%;
  margin-left: 8px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  overflow-y: hidden;
  margin-bottom: 16px;
}

.tab-label {
  text-transform: capitalize !important;
  font-family: "Inter", sans-serif !important;
}

.kpi-container {
  display: grid !important;
  grid-template-columns: 14fr 1fr;
  grid-gap: 10px;
  width: 100%;
  align-items: end;
  min-height: 48px;
}

.avatar-container {
  align-self: flex-start !important;
  margin-top: 8px !important;
  max-width: 7.5% !important;
}

@media screen and (max-width: 1319px) {
  .new-content {
    margin-left: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .new-chartRow {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0;
  }

  .new-rightPart {
    min-width: 450px;
  }

  .MuiToggleButton-root {
    padding: 0.3em;
  }

  .sentimentInsightsInfo {
    margin: auto 8px;
  }

  .tab-label {
    padding: 5px !important;
  }
}

@media screen and (max-width: 1320px) {
  .new-rightPart {
    height: 85.25vh;
  }
}

@media screen and (min-width: 1201px) {
  .kpi-container {
    flex-wrap: wrap !important;
  }

  .plus-icon {
    margin-left: auto !important;
  }

  .kpi-item {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 1300px) {
  .MuiToggleButton-root {
    padding: 0.3em !important;
  }

  .MuiSvgIcon-root {
    width: 0.7em !important;
  }

  .rhap_play-pause-button {
    width: 0.7em !important;
  }

  .rhap_container svg {
    width: 0.7em !important;
  }

  .rhap_additional-controls {
    margin-left: 0 !important;
  }

  .rhap_volume-button {
    width: 3em !important;
  }

  .rhap_volume-container {
    margin-right: 0.6em !important;
  }

  .rhap_container {
    padding: 10px 0px !important;
  }

  .rhap_main {
    padding: 0 16px !important;
  }
}

.participantInsightsDiv {
  display: contents;
  float: right;
}

.searchButton {
  display: inline;
  padding: 6px;
  font-size: 12px;
  display: inline;
  margin: auto;
  margin-right: 8px;
}

.searchButtonDisable {
  display: inline;
  padding: 6px;
  font-size: 12px;
  display: inline;
  margin: auto;
  margin-right: 8px;
  background: #1971c2 !important;
  border-radius: 10px;
}

.searchIconDisable:disabled {
  color: white !important;
}

.searchBox {
  height: 36px !important;
  width: 305px !important;
  right: 68px !important;
  top: 115px !important;

  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #1971c2;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  border-radius: 10px;
  position: fixed;
  z-index: 1;
  display: flex;
}

.searchTextBox {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #495057 !important;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  margin-left: 10px;
  width: 150px;
}

.searchCount {
  display: flex;
  align-items: center;
  color: #495057 !important;
}

.searchSeparator {
  margin-left: 6px !important;
}

.searchUpButton {
  color: #495057 !important;
}

.searchDownButton {
  right: 15px;
  color: #495057 !important;
}

.searchCloseButton {
  right: 28px;
  color: #495057 !important;
}

.disableButtonDefaultDesign:hover {
  background: none !important;
}

.circle {
  background-color: #ccc;
  border-radius: 50%;
  height: 24px;
  text-align: center;
  width: 24px;
}

.initials {
  font-size: 12px; /* 50% of parent */
  line-height: 1;
  position: relative;
  top: 2px; /* 25% of parent */
}
