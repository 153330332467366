.customErrorContainer {
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
}

.customErrorIcon {
  color: #1971c2;
  width: 50%;
  height: 50%;
}

.customErrorMessage {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
}
