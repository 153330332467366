.selector {
  /* border: 1px solid red; */
  width: 30%;
}

.chipTag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chipOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-kpiSelectorsForGraph > div {
  flex: 45%;
  margin: 0.5em;
}
