.bullet_item {
  display: inline-flex;
  align-items: baseline;
  width: 100%;
}

.action_item {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.bullet_item_icon {
  width: 8px;
  height: 8px;
  margin-left: 16px;
  margin-right: 4px;
}

.action_item_icon {
  display: flex;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 4px;
}
