.notdisplayed {
  display: none !important;
}

.displayed {
  display: block !important;
  margin: auto !important;
  position: absolute;
  opacity: 1 !important;
}

.disabledButton {
  cursor: not-allowed;
}

.buttonContainer {
  padding: 1px !important;
  z-index: 99999;
  background-color: #3277d2 !important;
  margin: 2px !important;
}

.buttonContainer:hover {
  transform: scale(1.1);
}

.chipHover {
  opacity: 0.2;
}

.pchipLabel {
  vertical-align: middle;
  color: #0d8244;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  white-space: break-spaces;
}

.pChipMain {
  background-color: #ebfbee !important;
  border-radius: 10px !important;
  min-height: 32px;
  height: auto !important;
}

.nchipLabel {
  vertical-align: middle;
  color: #c92a2a;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  white-space: break-spaces;
}

.nChipMain {
  background-color: #fff5f5 !important;
  border-radius: 10px !important;
  min-height: 32px;
  height: auto !important;
}

.tchipLabel {
  vertical-align: middle;
  color: #1971c2;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  white-space: break-spaces;
}

.tChipMain {
  background-color: #e7f5ff !important;
  border-radius: 10px !important;
  min-height: 32px;
  height: auto !important;
}
