.BulletContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.BulletLayout {
  display: flex;
  width: 100%;
}

.BulletSeperator {
  border-left: 7px solid #087f5b;
  margin-right: 14px;
}

.BulletContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.BulletCardTitleStyled {
  width: 79%;
}

.BulletIconContainerStyled {
  width: 10rem;
  display: flex;
  padding-left: 2.5rem;
}

.BulletContentInfoLayout {
  display: flex;
  padding-top: 2rem;
  justify-content: left;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.BulletContentInfoInnerLayout {
  width: 89%;
}

.BulletRadioContainerLayout {
  width: 23%;
  padding-left: 0.5rem;
}

.BulletLinkStyled {
  width: 100%;
  margin-bottom: 1rem !important;
}

.BulletLinkURLStyled {
  width: 100%;
}

.BulletTextContentStyled {
  width: 100%;
}
