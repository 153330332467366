.MainSectionLayout {
  width: 100%;
}

.MainInnerLayout {
  display: flex;
  width: 100%;
}

.SectionInputLayout {
  width: 85%;
}

.CardTitleStyled {
  width: 98%;
  border-radius: 10px;
}

.SectionCloseIconWrapper {
  width: 10%;
  margin-left: 20px;
}
