.kpis {
  /* border: 1px solid red; */
  height: 10px;
  width: 10px;
  color: #000;
  margin-right: 5px;
  margin-bottom: 1px;
}

.mkpis {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.ckpis {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
