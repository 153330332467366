div.size {
  width: 100%;
  height: calc(100vh / 2.3);
  border-radius: 10px;
}

@media screen and (max-height: 800px) {
  div.size {
    height: calc(100vh / 2.9);
  }
}

.thumbnail > ol > li > picture > img {
  height: 170px;
}

.myOverlay {
  text-align: left;
  padding: 2em;
}
.paper-trans {
  margin: "10px 20px";
  padding: "10px 20px";
  border-radius: "0px 7px";
}

.avatar-flex {
  display: inline-flex;
  margin-right: 10px;
}

.size > button.vjs-big-play-button {
  display: none;
}

.size > div.vjs-poster {
  border-radius: 10px;
}

.vjs-fluid {
  overflow: hidden;
}
.vjs-control-bar {
  display: block;
}
.vjs-control {
  position: absolute;
}
.vjs-progress-control {
  cursor: pointer;
  flex: auto;
  display: flex;
  align-items: center;
  min-width: 4em;
  touch-action: none;
}
.vjs-progress-holder {
  outline: 0;
  position: relative;
  cursor: pointer;
  padding: 0;
  background-color: #e7f5ff !important;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  flex: auto;
  transition: all 0.2s;
  height: 0.3em;
  border-radius: 20px;
}
.vjs-play-progress {
  background-color: #1971c2 !important;
  height: 0.3em;
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
}

.vjs-play-progress:before {
  font-family: VideoJs;
  font-size: 14px;
  position: absolute;
  right: -0.7em;
  top: -0.5em;
  z-index: 1;
  color: #1971c2;
  content: "\f112";
  background: white;
  border-radius: 100%;
}

.vjs-slider-handle {
  position: absolute;
  left: 0;
  top: 0;
}
.vjs-progress-holder:hover .vjs-time-tooltip {
  display: block;
  font-size: 0.6em;
  visibility: visible;
}

.vjs-time-tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.3em;
  color: #000;
  float: right;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  padding: 6px 8px 8px 8px;
  pointer-events: none;
  position: absolute;
  top: -2.4em;
  visibility: hidden;
  z-index: 1;
}
.vjs-current-time {
  display: block;
  left: 35px;
}
.vjs-time-divider {
  position: absolute;
  display: block;
  left: 70px;
}
.vjs-remaining-time {
  display: none;
}
.vjs-duration {
  display: block;
  left: 70px;
}
.vjs-volume-menu-button {
  position: absolute;
  bottom: 0;
  right: 55px;
}
.vjs-playback-rate {
  position: absolute;
  bottom: 0;
  right: 28px;
}
.vjs-fullscreen-control {
  position: absolute;
  bottom: 0;
  right: 0;
}
.video-js.vol {
  display: block;
  position: relative;
  box-sizing: border-box;
  color: #495057;
  font-size: 1rem;
  background-color: transparent;
  height: auto;
}

.video-js .vjs-volume-bar {
  margin: 0px !important;
  border-radius: 20px;
  position: relative;
}
.video-js .vjs-volume-level {
  background: #495057 !important;
  border-radius: 20px;
  position: absolute;
}

.vjs-volume-level:before {
  color: white;
  border: 0.3px solid #495057;
  border-radius: 60%;
  top: -0.4rem;
  font-size: 10px !important;
}

.vjs-has-started .vjs-control-bar {
  flex-direction: row-reverse;
}

.vjs-modal-dialog {
  border-radius: 10px;
}

.vjs-modal-dialog-content {
  font-size: 24px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative !important;
  display: flex;
  flex-wrap: wrap;
}
