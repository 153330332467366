.chipContainer {
  background-color: aqua;
  display: flex;
  margin: auto;
}

.notdisplayed {
  display: none !important;
}

.disabledButton {
  cursor: not-allowed;
}

.displayed {
  display: block !important;
  margin: auto !important;
  position: absolute;
  opacity: 1 !important;
}

.buttonContainer {
  padding: 1px !important;
  z-index: 99999;
  background-color: #3277d2 !important;
  margin: 2px !important;
}

.buttonContainer:hover {
  transform: scale(1.1);
}

.chipHover {
  opacity: 0.2;
}

.callChipMain {
  background-color: #e7f5ff !important;
  border-radius: 10px !important;
  min-height: 32px;
  height: auto !important;
}

.utteranceChipMain {
  background-color: #f1f3f5 !important;
  border-radius: 10px !important;
  min-height: 32px;
  height: auto !important;
}

.reviewedIconChip {
  color: green;
  margin-left: -7px;
  margin-right: 3px;
}

.chipLabel {
  vertical-align: middle;
  color: #495057;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  white-space: break-spaces;
}
