.fauxVideoContainer {
  background-color: #495057;
  width: 100%;
  height: calc(100vh / 2.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
}

@media screen and (max-height: 800px) {
  .fauxVideoContainer {
    height: calc(100vh / 2.9);
  }
}

.fauxVideoSpeakerInfo {
  color: #ffffff;
}

.fauxVideoKPIDiv {
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex !important;
  justify-content: flex-end;
  margin: 6px 6px 0px 6px !important;
}
