body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
  font-family: "Inter", sans-serif;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* Elastic Search CSS Changes */

.ant-card-head-title {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.search-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
}

.search-button-wrapper {
  display: flex;
}

.search-button-wrapper > h6 {
  margin-top: 3px;
  margin-left: 10px;
}

.keyword-search-place-holder {
  font-size: 16px !important;
  font-weight: 500;
}

/* .search-switch {
  font-size: 14px !important;
  background-color: #1b53c5 !important;
}

.ant-switch-checked {
  background-color: #1b53c5;
} */

.exact-match-keyword {
  font-size: 12px !important;
  color: #495057;
}

.exact-match-keyword:hover {
 cursor: pointer;
}

.elastic-search-divider {
  margin: 14px 0 !important;
}

.MuiChip-deleteIconMedium > path {
  fill: #546e7a !important;
}

.chips a span:last-of-type {
  color: #546e7a !important;
  border: none !important;
}

details > summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary::after {
  content: "";
  margin-left: 10px;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  display: inline-block;
  height: 0.45em;
  position: relative;
  top: 0.35em;
  left: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
}

details[open] summary:after {
  content: "";
  margin-left: 10px;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  display: inline-block;
  height: 0.45em;
  position: relative;
  top: 0.35em;
  left: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
  top: 0.55em;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.css-ee3byn-Select {
  border-radius: 4px !important;
  border: 2px solid #495057 !important;
}

.custom-link {
  color: #0070d2 !important;
}
