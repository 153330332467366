.mainContainer {
  display: flex;
  gap: 10px;
  background: rgb(239 239 239);
}
.columnOne {
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  height: calc(100vh - 80px);
  width: 60vh;
  transition: box-shadow 0.3s ease;
  background: white;
  padding: 10px;
  overflow: auto;
  max-width: calc((100% - 20px) / 3);
  flex: 1;
}

.columnOne:hover,
.columnTwo:hover,
.columnThree:hover {
  box-shadow: 9px 9px 20px 4px rgba(0, 0, 0, 0.1);
}

.addCategoryBtn {
  width: 85%;
  height: 50px;
}

.columnTwo {
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  height: calc(100vh - 80px);
  width: 60vh;
  transition: box-shadow 0.3s ease;
  background: white;
  overflow: auto;
  padding: 10px;
  max-width: calc((100% - 20px) / 3);
  flex: 1;
  display: inline-grid;
}

.columnThree {
  margin-top: 10px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  height: calc(100vh - 80px);
  width: 60vh;
  transition: box-shadow 0.3s ease;
  background: white;
  overflow: auto;
  padding: 10px;
  max-width: calc((100% - 20px) / 3);
  flex: 1;
  display: inline-grid;
}

.addCategoryButtons {
  display: flex;
  gap: 10px;
  margin-top: -30px;
}
.addSubCategoryButtons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.addSubCategoryBtn {
  height: 50px;
  margin-top: 50px !important;
}

.removeButton {
  margin-left: 10px !important;
}

.timelineButtons {
  text-transform: none !important;
}

.errorDiv {
  background: rgb(253, 236, 234);
  padding: 5px;
  min-height: 50px;
  margin-top: 10px;
}

.timeLineContainer {
  overflow: auto;
  max-height: calc(100vh - 300px);
}
.timeLineContainerSecond {
  overflow: auto;
  max-height: calc(100vh - 270px);
  min-height: calc(100vh - 325px);
}
.headings {
  background-color: rgb(235 237 236 / 50%);
  padding: 5px;
}
.submitButton {
  height: 50px;
  margin-top: 10px !important;
}
.skipButton {
  margin-top: 10 px !important;
  height: 50px;
}
