.cardModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  padding: 18px;
  border: 2px solid #1976d2;
}

.cardModalSub {
  /* overflow-y: auto;
    max-height: 100%; */
}

.alert {
  margin-bottom: 8px;
}

.applyError {
  border: 1px solid red !important;
}

.divider {
  align-self: center;
  margin-top: 12px !important;
}

.saveCardDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.LeftOverCountStyled {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0070d2;
  cursor: pointer;
}

.CardHeaderStyled {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  margin-top: 2%;
}

.CardTitleStyled {
  width: 93%;
  border-radius: 10px;
}

.EqualizerRoundedStyled {
  width: 65px !important;
  height: 50px !important;
}

.SectionLayoutStyled {
  overflow-y: auto;
  overflow-x: hidden;
  height: 10%;
}

.SectionContainerStyled {
  margin-top: 14px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.SectionTitleLineStyled {
  border-left: 7px solid #1971c2;
  margin-right: 0.8rem;
  width: 2%;
}

.actionButton {
  box-shadow: none !important;
  width: 100px;
  border-radius: 10px !important;
  border: none !important;
  color: #1971c2 !important;
  text-transform: capitalize !important;
}

.actionButton:disabled {
  box-shadow: none !important;
  border-radius: 10px !important;
  color: lightgrey !important;
}

.actionButton:hover {
  background-color: #e7f5ff !important;
}

.actionButtonSave {
  border: 1px solid #1971c2 !important;
}

.actionButtonSave:disabled {
  border: 1px solid lightgrey !important;
}
